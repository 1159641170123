import React, { useEffect } from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import Slider from "../components/slider"
import BlogPosts from "../components/blogposts"
import { Link } from "gatsby"
import Blog from "../templates/blog"

const Home = (props) => {
    useEffect(() => {
        const installGoogleAds = () => {
            const elem = document.createElement("script");
            elem.src =
              "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
            elem.async = true;
            elem.defer = true;
            document.body.insertBefore(elem, document.body.firstChild);
            elem.onload = () => {
                try {
                    const adsbygoogle = window.adsbygoogle || []
                    adsbygoogle.push({})
                } catch (e) {
                    console.error(e)
                }
            };
          };
          installGoogleAds();

    }, [])
    return (
        <Layout css_class="home">
            <Head title="Home" />
            <Slider />
            <main>
                <div className="container">
                    <section>
                        <BlogPosts limit={3} data={props} />
                    </section>

                    <ins className="adsbygoogle"
                         style={{"display":"block"}}
                         data-ad-client="ca-pub-1435869001586096"
                         data-ad-slot="7917509468"
                         data-ad-format="auto"
                         data-full-width-responsive="true"></ins>

                    <section style={{textAlign: "center", marginTop: "20px"}}>
                        <Link to={`/blog/`} className="button button-primary">More Articles</Link>
                    </section>
                </div>
            </main>
        </Layout>
    )
}

export default Home