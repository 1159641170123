import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';

import * as sliderStyles from '../styles/modules/slider.module.scss';

const Slider = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "assets/img/slide.jpg" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);
  
    return (
        <div className={sliderStyles.slider}>
            <div className="slides">
                <img src={data.file.childImageSharp.fluid.src} srcSet={data.file.childImageSharp.fluid.srcSet} />
            </div>
            <div className={sliderStyles.content}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                          <span>YOUR COMPANION</span> in <br/>
                          the kitchen
                          <small>lets cook together</small>
                          <a href="/blog/" className="button button-primary">Read the blog</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slider