import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import blogPostsStyles from "../styles/modules/blogposts.module.scss"

const BlogPosts = () => {
    const data = useStaticQuery(graphql`
    query {
        allWpPost(limit: 4, sort: {order: DESC, fields: date}) {
          nodes {
            title
            excerpt
            slug
            featuredImage {
                node {
                    localFile{
                        childImageSharp {
                            fluid(maxWidth: 1180) {
                              ...GatsbyImageSharpFluid_withWebp
                            }
                          }
                    }
                  sourceUrl
                  title
                }
              }
          }
        }
      }
    `)
    
    return (
        <div className="posts-layout--two-col">
            <div className="row">
                <ul>
                    {data.allWpPost.nodes.map(node => {
                        return (
                            <li key={node.slug}>
                                {node.featuredImage && (
                                    <Link
                                        to={`/blog/${node.slug}/`}
                                    >
                                        <Img fluid={node.featuredImage.node.localFile.childImageSharp.fluid} key={node.featuredImage.node.localFile.childImageSharp.fluid.src} />
                                    </Link>
                                )}
                                <div className="posts-layout__content">
                                    <Link
                                        to={`/blog/${node.slug}/`}
                                    >
                                        <h2>{node.title}</h2>
                                    </Link>
                                    <div dangerouslySetInnerHTML={{ __html: node.excerpt.replace(/<a class="more-link.*/g, '') }}></div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default BlogPosts